<template>
  <div class="container">
    <b-form @submit.prevent="onSubmit">
      <h3>Восстановление пароля</h3>

      <b-form-group id="email" label-for="email" label="Email">
        <b-form-input id="email" type="text" placeholder="" required v-model="email" autocomplete="off"></b-form-input>
      </b-form-group>



      <button class="save_btn_common" type="submit" >Отправить email<b-spinner v-if="saving" variant="light" small></b-spinner></button>

      <div>
        Защита от спама reCAPTCHA
        <a href="https://policies.google.com/privacy">Конфиденциальность</a> и
        <a href="https://policies.google.com/terms">Условия использования</a>.
      </div>
      &nbsp;

    </b-form>

    <br />
    <br />

    <b-modal id="clinicModal" hide-footer size="lg" title="Успешная регистрация" @close="closeModal()" no-close-on-backdrop no-close-on-esc >
      <div>
        На ваш email, указанный при регистрации, отправлено письмо. Пожалуйста, следуйте инструкциям из письма для сброса пароля.
      </div>

    </b-modal>

  </div>
</template>

<script>
import {host} from "@/constants";

export default {
  name: "PasswordRecover",
  data() {
    return {
      email: '',
      token: null,
      saving: false,
    }
  },
  methods: {
    async onSubmit() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      this.token = await this.$recaptcha('pwdrecover')


      this.saving = true
      let method = 'POST'

      let rs = await fetch(host + '/rest/register/password/recover', {
        method: method,
        credentials: 'include',
        body: JSON.stringify({ email: this.email, token: this.token}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        this.$errorToast(json.resultCode + ' ' + json.message)
        this.saving = false
        return
      } else {
        this.$bvModal.show('clinicModal')
      }

      // let localDocId = this.doctorId
      // if (!this.doctorId) {
      //   localDocId = json.entityId
      // }
      // json = await this.updateDoctorClinics(localDocId)
      // if(json) {
      //   this.loading = false
      //   return
      // }
      // json = await this.updateDoctorSpecializations(localDocId)
      // if(json) {
      //   this.saving = false
      //   return
      // }
      this.saving = false
      //this.$router.push('/doctors')

    },
    closeModal() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>